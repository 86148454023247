import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { AnimatePresence, motion } from "framer-motion";

interface WebLayoutProps {
  children: React.ReactNode;
}

interface TrailPoint {
  x: number;
  y: number;
  id: number;
}

const WebLayout: React.FC<WebLayoutProps> = ({ children }) => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState(0);
  const [trails, setTrails] = useState<TrailPoint[]>([]);
  const lastMoveTimeRef = useRef<number>(Date.now());
  const animationFrameRef = useRef<number>();
  // const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false); // 是否播放影片

  const navItems = [
    { title: "首頁", path: "/threads/web/home" },
    { title: "角色介紹", path: "/threads/web/actors" },
    // { title: "活動資訊", path: "/threads/web/map" },
    // { title: "禮鮑內容", path: "/threads/web/package" },
  ];

  // 根据当前路径设置 activeIndex
  useEffect(() => {
    const currentPath = router.pathname;
    const index = navItems.findIndex((item) => item.path === currentPath);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [router.pathname]);
  useEffect(() => {
    navItems.forEach((item) => {
      router.prefetch(item.path);
    });
  }, [navItems, router]);

  // 监听路由变化, 路由切换后自动滚动到顶部
  useEffect(() => {
    const handleRouteChange = () => {
      // 每次路由切换后，滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  // 滚动防抖/冷却处理
  const lastScrollTimeRef = useRef<number>(0);
  const scrollCooldown = 800; // 设置滚动切换页面的冷却时间（毫秒）

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      const now = Date.now();
      if (now - lastScrollTimeRef.current < scrollCooldown) {
        // 冷却时间内不触发路由切换
        return;
      }

      const deltaY = e.deltaY;
      if (deltaY > 0) {
        // 向下滚动，尝试��下一个页面
        if (activeIndex < navItems.length - 1) {
          lastScrollTimeRef.current = now;
          router.push(navItems[activeIndex + 1].path);
        }
      } else if (deltaY < 0) {
        // 向上滚动，尝试去个页面
        if (activeIndex > 0) {
          lastScrollTimeRef.current = now;
          router.push(navItems[activeIndex - 1].path);
        }
      }
    },
    [activeIndex, navItems, router]
  );

  useEffect(() => {
    // 在组件挂载后监听全局滚轮事件
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [handleWheel]);
  // 鼠标轨迹相关逻辑
  const handleMouseMove = useCallback((e: MouseEvent) => {
    const { clientX, clientY } = e;
    lastMoveTimeRef.current = Date.now();

    const newTrail: TrailPoint = {
      x: clientX,
      y: clientY,
      id: Date.now(),
    };

    setTrails((prev) => {
      if (prev.length === 0) return [newTrail];

      const lastPoint = prev[prev.length - 1];
      const distance = Math.hypot(clientX - lastPoint.x, clientY - lastPoint.y);

      if (distance < 5) return prev;

      return [...prev, newTrail].slice(-15);
    });
  }, []);

  useEffect(() => {
    const animate = () => {
      const now = Date.now();
      const timeSinceLastMove = now - lastMoveTimeRef.current;

      if (timeSinceLastMove > 50) {
        // 鼠标停止移动后，逐渐移除轨迹点
        setTrails((prev) => {
          if (prev.length <= 1) return [];
          return prev.slice(1);
        });
      }

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const handleNavClick = (path: string, index: number) => {
    setActiveIndex(index);
    if (path !== "#") {
      router.push(path);
    }
  };

  const handleShare = (type: string) => {
    const socialLinks: { [key: string]: string } = {
      line: `https://lin.ee/lzDzOrw`,
      telegram: `https://t.me/+WB3vqddQUHliZmU1`,
      instagram: `https://www.instagram.com/mdclub01/`,
      twitter: `https://x.com/mdclub01`,
    };

    window.open(socialLinks[type], "_blank");
  };

  // 点击预购
  const seeMovieClick = () => {
    window.open(
      "https://modelmediaasia.com/zh-CN/videos/MDL-0010-1?utm_source=threads&utm_medium=pussygame2&utm_campaign=exclusive_website",
      "_blank"
    );
  };

  const handleCouponClick = () => {
    window.open(
      "https://modelmediaasia.com/zh-CN/videos/MDL-0010-1?utm_source=threads&utm_medium=pussygame2&utm_campaign=exclusive_website",
      "_blank"
    );
  };

  // 判断是否是首页和角色介绍
  const isHomeOrRole = () => {
    return [0, 1].includes(activeIndex);
  };

  // 使用 AnimatePresence 和 motion 在路由变化时添加页面过渡动画
  return (
    <div className={styles.layoutContainer}>
      <svg className={styles.mouseTrail}>
        {trails.map((point, index) => {
          if (index === 0) return null;
          const prevPoint = trails[index - 1];
          const opacity = Math.max(0.1, index / trails.length);

          return (
            <line
              key={point.id}
              x1={prevPoint.x}
              y1={prevPoint.y}
              x2={point.x}
              y2={point.y}
              className={styles.trail}
              style={{
                opacity: opacity,
                strokeWidth: "2px",
              }}
            />
          );
        })}
      </svg>

      <nav className={styles.sidebarNav}>
        {navItems.map((item, index) => (
          <Link
            href={item.path}
            key={item.title}
            prefetch={true}
            className={classNames(styles.navItem, {
              [styles.active]: activeIndex === index,
            })}
          >
            <div className={styles.circle}></div>
            <span>{item.title}</span>
            <div className={styles.square}></div>
          </Link>
        ))}
      </nav>
      <div className={styles.shareButtons}>
        <Image
          src="/img/share.png"
          height={252}
          width={48}
          alt="share"
          className={styles.shareImage}
          draggable={false}
        />
        <div className={styles.shareAreas}>
          <div onClick={() => handleShare("line")} />
          <div onClick={() => handleShare("telegram")} />
          <div onClick={() => handleShare("instagram")} />
          <div onClick={() => handleShare("twitter")} />
        </div>
      </div>
        <Image
          src="/img/enterCoupon_01.png"
          height={102}
          width={400}
          alt="share"
          className={styles.enterCoupon}
          draggable={false}
          onClick={handleCouponClick}
          style={{ cursor: "pointer" }}
        />

      {/* {isVideoPlay && (
        <div
          className={styles.video_popup}
          onClick={() => setIsVideoPlay(false)}
        >
          <video
            className={styles.video}
            onClick={(event) => event.stopPropagation()}
            src="https://v.pussygame2.com/baoyu123.mp4"
            autoPlay
            controls
            loop
          ></video>
        </div>
      )} */}

      {/* {!isHomeOrRole() && (
        <Image
          src="/img/enterCoupon.png"
          height={170}
          width={400}
          alt="coupon"
          className={styles.enterCoupon}
          draggable={false}
          onClick={handleCouponClick}
          style={{ cursor: "pointer" }}
        />
      )} */}

      <AnimatePresence mode="wait">
        <div className={styles.animationWrapper}>
          {" "}
          {/* 新增包裹层 */}
          <motion.main
            key={router.asPath} // 路由变化时更改key以触发过渡动画
            className={styles.mainContent}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {children}
          </motion.main>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default WebLayout;
